
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import user from '../../assets/profile-circled.svg'
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import orderRoute from '../../services/orderRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/deactivate.svg'
import calendarGreen from '../../assets/calendar-green.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel, DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from "react-icons/fa";
import { IoCardOutline } from "react-icons/io5";
import { IoCalendarClearOutline } from "react-icons/io5"
import DeleteProductModal from '../../components/Modal/DeleteProductModal';
import adminRoute from '../../services/adminRoute';
import { debounce } from 'lodash';



const AdminCustomers = () => {
  const { showToast,showDeleteModal, setShowDeleteModal } = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerDisplayDetails, setCustomerDisplayDetails] = useState([]);
  const [globalCustomers, setGlobalCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [loading, setLoading] = useState(false);

  const {getAdminCustomers, filterCustomersByDate,getGlobalCustomers, filterCustomersBySource} = adminRoute()
  const [exportFormat, setExportFormat] = useState(null); 
  const [selectedCustomersId, setSelectedCustomersId] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
 
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isSourceOpen, setIsSourceOpen] = useState(false);
  const [isUserGroupOpen, setIsUserGroupOpen] = useState(false);
  const [pagination, setPagination] = useState({
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalCustomers: 0,
  });
  const tableRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);


  
useEffect(() => {

  getGlobalCustomers(
    setLoading, showToast, setGlobalCustomers, 
   
  )
    
  }, []);
 
  useEffect(() => {
    // Define a debounced version of the getAdminCustomers function
    const debouncedGetAdminCustomers = debounce((searchCustomer) => {
        getAdminCustomers(
            setLoading,
            showToast,
            setCustomerDetails,
            setCustomerDisplayDetails,
            pagination,
            setPagination,
            searchCustomer,
        );
    }, 500);

  
    if (searchCustomer !== '') {
      setSearchPerformed(true)
        debouncedGetAdminCustomers(searchCustomer);
    } else {
        // If the search product is empty, call getAdminCustomers immediately
        getAdminCustomers(
            setLoading,
            showToast,
            setCustomerDetails,
            setCustomerDisplayDetails,
            pagination,
            setPagination,
            
        );
    }

  
    return () => {
        debouncedGetAdminCustomers.cancel();
    };
}, [pagination.currentPage, searchCustomer]);
 

      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
           if (dropdownRef6.current && !dropdownRef6.current.contains(event.target)) {
            setIsSourceOpen(false);
          }
          if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsDateOpen(false);
          }
          if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setIsExportOpen(false);
          }
          if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
            setIsUserGroupOpen(false);
          }
          if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
            setIsStatusOpen(false);
          }
        }
    
        function handleScroll() {
          setIsOpen(false);
          setIsOpen2(false);
          setIsStatusOpen(false);
          setIsDateOpen(false);
          setIsSourceOpen(false);
          setIsExportOpen(false);
          setIsUserGroupOpen(false)
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);

    
      
  
      
      

const toggleDropdown = () => setIsOpen(!isOpen);
const toggleDropdown2 = () => setIsOpen2(!isOpen2);

const toggleStatusDropdown = () => setIsStatusOpen(!isStatusOpen);
const toggleSourceDropdown = () => setIsSourceOpen(!isSourceOpen);

const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
const handleFocus = () => {
  setIsFocused(true)
};

const handleBlur = () => {
  setIsFocused(false);
};
const handlePageChange = (page) => {
  setPagination(prevState => ({
    ...prevState,
    currentPage: page
  }));
};

const handleToggleSelectAll = () => {
  if (selectedCustomersId.length === customerDisplayDetails.length) {
      setSelectedCustomersId([]); // Deselect all
  } else {
      setSelectedCustomersId(customerDisplayDetails.map(customer => customer.id)); // Select all
  }
};

const handleCheckboxChange = (customerId, isChecked, event) => {

  
  if (isChecked) {
      setSelectedCustomersId(prevIds => [...prevIds, customerId]);
  } else {
      setSelectedCustomersId(prevIds => prevIds.filter(id => id !== customerId));
  }
};



const capitalizeFirstLetter = (string) => {
  return string?.replace(/\b(\w)/g, s => s.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'active':
      return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'6px 16px 6px 16px', borderRadius:'12px' };
    
    case 'pending':
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px', borderRadius:'12px' };
    case 'inactive':
      
    return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'12px' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #FEF1F2', padding:'6px 16px 6px 16px',borderRadius:'12px' };
  }
};

// const determineStatus = (customer) => {
//   // const status = customer?.user?.status?.toLowerCase() || 'unknown';
//   const statusText = customer?.user?.status ? capitalizeFirstLetter(customer.status) : 'Unknown';
//   const statusStyle = getStatusStyle(statusText?.toLowerCase());
//   return <span style={statusStyle}>{statusText}</span>;
// };


const determineStatus = (customer) => {
  let statusText = 'Unknown';
  let statusStyle;

  if (customer?.user && customer?.user?.status) {
      statusText = capitalizeFirstLetter(customer?.user?.status);
      statusStyle = getStatusStyle(customer?.user?.status?.toLowerCase());
  } else {
      // Fallback styles if product or stockLevel is undefined
      statusStyle = { color: '#CE371C', border: '1px solid #CE371C', padding: '6px 16px', borderRadius: '12px' };
  }

  return <span style={statusStyle}>{statusText}</span>;
};



const handleFilter = () => {
  const startOfStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));
  const isoStartDate = startOfStartDate.toISOString();

  // Set the end date to the beginning of the day in UTC
  const startOfEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));
  const isoEndDate = startOfEndDate.toISOString();
  setIsDateOpen(false);
  filterCustomersByDate(isoEndDate, isoStartDate, setLoading, setCustomerDisplayDetails, showToast,setPagination);
 // Close the date picker dropdown
};



const handleCSVExport = () => {
  const csvData = prepareCSVData();
  if (csvData) {
    return (
      <CSVLink data={csvData.data} headers={csvData.headers} filename="customer-list.csv" className='csv-link'>
        As CSV
      </CSVLink>
    );
  } else {
    console.error("CSV data is not available.");
  }
};

const prepareCSVData = () => {
  if (globalCustomers?.length === 0) {
    console.warn("No products available for CSV export.");
    return null;
  }
  
  const headers = [
    { label: 'Profile Image', key: 'profileImage' },
    { label: 'Name', key: 'customerName' },
    { label: 'Email', key: 'customerEmail' },
   
    { label: 'Total Orders Placed', key: 'totalOrders' },
    { label: 'Sign Up Date', key: 'signupDate' },

    { label: 'Status', key: 'status' },
    
  ];
  
  const data = globalCustomers?.map(customer => ({
    profileImage: customer?.profilePicture || '',
    customerName:  `${customer?.user?.firstName} ${customer?.user?.lastName}`,
    customerEmail: customer.email,
    totalOrders: customer.totalOrderCount,
    signupDate: customer?.createdAt ? new Date(customer.createdAt).toLocaleDateString('en-CA') : 'N/A',
    status: customer?.status, 
  }));
  
  return { headers, data };
};



const handlePDFExport = () => {
const input = document.getElementById('customer-table');
html2canvas(input).then((canvas) => {
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 190;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  pdf.save('customer-list.pdf');
});
};

const getAllCustomers = ()=> {
  getAdminCustomers(
    setLoading,
    showToast,
    setCustomerDetails,
    setCustomerDisplayDetails,
    pagination,
    setPagination,
    
);
}

const filterCustomersByStatus = (status) => {
  const filtered = globalCustomers.filter(customer => customer?.user?.status?.toLowerCase() === status?.toLowerCase());
  if(filtered.length === 0){
    showToast({toastText:`No customers are ${status}`, isSuccess:false})
    return
  }else{
  setCustomerDisplayDetails(filtered);
  setPagination({
    currentPage: 1,
    itemsPerPage: 20,
    totalPages: 0,
    totalOrders: 0,
  })
}
};


const filterCustomersBySourceStatus = (status) => {
  filterCustomersBySource(status, setLoading, setCustomerDisplayDetails, showToast,setPagination)
};



return (
  <section className=''>
  <div className='d-flex justify-content-between align-items-center header-admin  py-4 pe-5 mb-4 ps-4' fixed='top'>
      <h3 style={{color:'#344054'}}>Customers</h3>
   <div className='d-flex gap-3'>
   <Dropdown show={isExportOpen}  ref={dropdownRef3}>
      <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 px-3 py-2'>
      <img src={exportIcon} />Export
      </Button>
      <Dropdown.Menu>
      <Dropdown.Item >
      <DownloadTableExcel
                    filename="customer table"
                    sheet="customers"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
        <DropdownDivider />
     
        {handleCSVExport()}
    
    
       
      </Dropdown.Menu>
    </Dropdown>
  
    
     

    <Button className='d-flex align-items-center gap-2 py-2 px-3'  >
    <i className='bi bi-person'></i>
  Add New Customer
    </Button>

    
   </div>
  </div>
  <div className=' pe-5 ps-4 '>

 {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):customerDisplayDetails?.length > 0 ? <>

  <div className='d-flex align-items-center justify-content-between py-4'>
    <div className='d-flex align-items-center gap-5'>
     
     
{selectedCustomersId.length > 0 ? (
  <Form.Check
  checked={selectedCustomersId.length === customerDisplayDetails.length}
  label={`${selectedCustomersId.length} selected`}
  className='selected-check'
  onClick={handleToggleSelectAll}
/>
) : <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'>{ pagination?.totalCustomers !== 0 ? pagination?.totalCustomers : customerDisplayDetails?.length} {pagination?.totalCustomers.length > 1 || customerDisplayDetails.length > 1 ? 'Customers' :'Customer'}</p> }


      <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500', width:'300px'}}>
      {!isFocused && searchCustomer === '' && (<>
      <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
  
       </>)}
       {searchCustomer !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchCustomer('')}></i>}
      <Form.Control
      style={{border: 'none', backgroundColor: 'transparent'}}
      placeholder="Search by customer's name"
      className='w-100'
      value={searchCustomer}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      onChange={(e)=>setSearchCustomer(e.target.value)}
      />
    </div>
    </div>
     
      <div className='d-flex gap-3'>
         
      
 
 <Button variant='secondary'  onClick={()=> getAllCustomers()} className='d-flex align-items-center gap-2 py-1 px-3'>
        All 
        </Button>
        <Dropdown show={isSourceOpen}  ref={dropdownRef6}>
 
   <Button variant='secondary' id="dropdown-basic" onClick={toggleSourceDropdown} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#626262'}}>
   Source {isSourceOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
    {isSourceOpen && [ 'Instagram', 'Facebook', 'TikTok', 'X (Twitter)', 'WhatsApp', 'Friend', 'Other'].map((status, index) => (
  <Dropdown.Item
    key={index} onClick={()=> filterCustomersBySourceStatus(status)}
  > {status}
  </Dropdown.Item>
))}
  </Dropdown.Menu>
 </Dropdown>
 <Dropdown show={isStatusOpen}  ref={dropdownRef4}>
 
   <Button variant='secondary' id="dropdown-basic" onClick={toggleStatusDropdown} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#626262'}}>
   Status {isStatusOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
    {isStatusOpen && [ 'Active', 'Inactive'].map((status, index) => (
  <Dropdown.Item
    key={index} onClick={()=> filterCustomersByStatus(status)}
  > {status}
  </Dropdown.Item>
))}
  </Dropdown.Menu>
 </Dropdown>
 <Dropdown show={isDateOpen}  ref={dropdownRef2} >
 <Button variant='secondary' id="dropdown-basic" onClick={() => setIsDateOpen(!isDateOpen)} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#626262'}}>
   Date {isDateOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  

  <Dropdown.Menu className='me-5' >
 
 {isDateOpen && (
   <div className='d-flex flex-column gap-3 p-3 ' style={{width:'180px',overflowX:'hidden'}}>
     <Form.Group>
     <Form.Label>Start Date</Form.Label>
     <DatePicker
       selected={startDate}
       onChange={(date) => setStartDate(date)}
       selectsStart
       startDate={startDate}
       endDate={endDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
     <Form.Group>
     <Form.Label>End Date</Form.Label>
     <DatePicker
       selected={endDate}
       onChange={(date) => setEndDate(date)}
       selectsEnd
       startDate={startDate}
       endDate={endDate}
       minDate={startDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
    
     <Button className='text-center w-30' onClick={handleFilter}>Filter</Button>
   </div>
 )}

</Dropdown.Menu>
</Dropdown>

      </div>
  </div>

  <Table hover className='my-3' ref={tableRef} id="customer-table">
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
          <th style={{fontWeight:'600'}} className='pb-3'></th>
          <th style={{fontWeight:'600', width:"50px"}} className='pb-3'></th>
              <th style={{fontWeight:'600',width:'8%'}} className='pb-3'>Customer Name</th>
             
              <th style={{fontWeight:'600',width:'8%'}} className='pb-3'> Email Address</th>
              <th style={{fontWeight:'600', }} className='pb-3'> Orders</th>
              <th style={{fontWeight:'600',width:'12%'}} className='pb-3'>Sign Up Date</th>
              
              <th style={{fontWeight:'600',width:'12%'}} className='pb-3'>Status</th>
              <th style={{fontWeight:'600',width:'8%'}} className='pb-3'>Source</th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2 '>
          {customerDisplayDetails?.map((customer) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={customer?.id}  className='align-items-center'>
              <td style={{paddingTop: '15px', paddingBottom: '15px' }}> 
        
  <div>
      <Form.Check
          checked={selectedCustomersId.includes(customer.id)}
          onChange={(e) => {
              handleCheckboxChange(customer.id, e.target.checked);
          }}
      />
  </div>


</td>
<td style={{}}> <img src={customer?.user?.profilePicture ? customer?.user?.profilePicture : user} style={{width:'40px', height:'40px', borderRadius:"50%"}}/></td> 
             <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#115F04', cursor:'pointer', textDecoration:'underline',width:'8%'}} onClick={() => navigate(`/admin/customers/${customer?.user?.id}`)}>{customer?.user?.firstName}&nbsp;{customer?.user?.lastName}</td>
       
             
             <td style={{paddingTop: '15px', paddingBottom: '15px',width:'8%' }}>{customer?.email}</td>
             <td style={{padding:'15px',}}>{customer?.user?.totalOrderCount}</td> 
             <td style={{paddingTop: '15px', paddingBottom: '15px',width:'12%'}}>{customer?.createdAt ? new Date(customer.createdAt).toLocaleDateString('en-CA') : 'N/A'}</td> 
           
             <td style={{paddingTop: '15px', paddingBottom: '15px',width:'12%' }}>{determineStatus(customer)}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px',width:'8%' }}>{customer?.user?.leadSource ? customer?.user?.leadSource : '-'}</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
          <img src={edit}/> &nbsp;&nbsp; <img src={deleteIcon} onClick={() => { setCurrentCustomer(customer);
    setShowDeleteModal(true);
}}/></td>
             <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/customers/${customer?.user?.id}`)}>View</td>
            </tr>
          ))}
          </tbody>
  </Table> 

  { customerDisplayDetails.length > 0 && pagination.totalPages > 1 &&  (

<div className='d-flex justify-content-between align-items-center my-5 pt-5' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>Showing {customerDisplayDetails?.length} of {pagination.totalCustomers} Results</p>
  {pagination.totalPages > 1 &&
<Pagination className='custom-pagination d-flex gap-2' >
 
<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}

</div>)}
</>
 :
 <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no customers</h4></div>
}
</div>
<DeleteProductModal  item={currentCustomer}
      itemType="customer"
    
    />

  </section>

)
}

export default AdminCustomers
