
import createAxios from './httpCommon';
import Error from './error';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../mixpanel';
import { transformPath } from '../App';
import { getParsedLocalStorageItem } from '../components/Navbar/Navbar';
import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../App';


export default () => {
  const { error } = Error();
  const navigate = useNavigate();
  const userData = getParsedLocalStorageItem('userData');

const getGlobalOrders = (
  setLoading,
  showToast,
  setGlobalOrders,
  searchOrder = '',
) => {
  const token = localStorageGetItem('userToken');
  setLoading(true);
  createAxios().then((axios) => {
    let url = '/admin/order/all';
    // Append search term to URL if provided
    if (searchOrder) {
      url += `?search=${searchOrder}`;
    }
    axios.get(url,{
      headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {
     

  
          setGlobalOrders(response?.data?.data);
         setLoading(false);
        
      }).catch((e) => {
        // setLoading(false);
         const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone); 
      });
  });
};

const getAdminOrders = (
  setLoading,
  showToast,
  setOrderDetails,
  setOrderDisplayDetails,
  pagination,
  setPagination,
  searchOrder = '',
 
) => {
  let currentPage = 1;
  let itemsPerPage = 20;

  // Adjust pagination if it exists
  if (pagination) {
    currentPage = pagination.currentPage;
    itemsPerPage = pagination.itemsPerPage;
  }
  const token = localStorageGetItem('userToken');
  setLoading(true);
  createAxios().then((axios) => {
    let url = `/admin/order/all?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
    // Append search term to URL if provided
    if (searchOrder) {
      url += `&search=${searchOrder}`;
    }

    axios.get(url,{
      headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {
    
   
          setOrderDetails(response?.data?.data?.orders);
          setOrderDisplayDetails(response?.data?.data?.orders);

        // Update pagination state
        if (setPagination) {
          setPagination({
            currentPage: response?.data?.data?.pagination.currentPage,
            itemsPerPage,
            totalPages: response?.data?.data?.pagination.totalPages,
            totalOrders: response?.data?.data?.pagination.totalOrders,
          });
        }
        setLoading(false);
        
      }).catch((e) => {
        // setLoading(false);
         const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone); 
      });
  });
};

const getAdminSingleOrder = (
  
  orderId, setOrderLoading,showToast, setOrderDetail
  
  
) => {
  
  setOrderLoading(true);
  const token = localStorageGetItem('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .get(`/admin/order/${orderId}`, {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
     
         setOrderDetail(response?.data?.order)
         setTimeout(() => {
             
          setOrderLoading(false);
      }, 1000);
        })
       
        .catch((e) => {
          setOrderLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);
        
        
      
       
          
        });
    });
};
const orderModify = (
  orderId,body,
  setLoading,showToast
  
  
) => {
  
  setLoading(true);

  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .put(`/admin/order/modify/${orderId}`, body
      , {
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then(async (response) => {
          setLoading(false);
     
          showToast({
            toastText: 'Order modified succesfully',
            isSuccess: true
          })
          navigate('/admin/orders')
         
        })
       
        .catch((e) => {
          
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
       
          
        });
    });
};

const filterOrders = (
  setLoading,
  setOrderDisplayDetails,
  showToast,
  pagination = null,
  setPagination = null,
  filters = {}
) => {
  setLoading(true);
  const token = localStorageGetItem
  ('userToken');
  createAxios().then((axios) => {
    // Initialize URL
    let url = '/admin/order/all';
    
    const params = new URLSearchParams();
    
    if (filters.isoStartDate) {
      params.append('startDate', filters.isoStartDate);
    }
    if (filters.isoEndDate) {
      params.append('endDate', filters.isoEndDate);
    }
    if (filters.orderStatusType) {
      params.append('orderStatus', filters.orderStatusType);
    }
    if (filters.deliveryType) {
      params.append('deliveryType', filters.deliveryType);
    }

    // Add the parameters to the URL
    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    axios
      .get(url,{
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then((response) => {
    

        if (response?.data?.data?.length === 0) {
          setLoading(false);
          if ((filters.isoStartDate || filters.isoEndDate) && !filters.orderStatusType && !filters.deliveryType) {
            showToast({ toastText: 'No orders within the dates selected', isSuccess: false });
          } else if ((!filters.isoStartDate || !filters.isoEndDate) && filters.orderStatusType && !filters.deliveryType) {
            showToast({ toastText: 'No orders with this status ', isSuccess: false });
          } else if ((!filters.isoStartDate || !filters.isoEndDate) && !filters.orderStatusType && filters.deliveryType) {
            showToast({ toastText: 'No orders with this delivery type', isSuccess: false });
          }
          else (
            showToast({ toastText: 'No orders match', isSuccess: false })
          )
        } else {
          setOrderDisplayDetails(response.data.data); 
          if (setPagination) {
            setPagination({
              currentPage: 1,
              itemsPerPage: 20,
              totalPages: 0,
              totalOrders: 0,})
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((e) => {
        const retriesDone = e.config['axios-retry']
          ? e.config['axios-retry'].retryCount >= 1
          : true;
        error(e, setLoading, showToast, retriesDone);
      });
  });
};
const filterOrdersByDate = (isoEndDate, isoStartDate, setLoading, setOrderDisplayDetails, setVisualOrderStatus, showToast, setPagination=null) => {
  const token = localStorageGetItem('userToken');
  setLoading(true)

  createAxios().then((axios) => {
    axios.get(`/admin/order/all?startDate=${isoStartDate}&endDate=${isoEndDate}`,{
      headers: {
        'X-Auth-Token': token,
      },
    })
    .then((response) => {
  
      setLoading(false)
      if(response.data.data.length === 0){
        showToast({
          toastText: 'No orders within the dates selected',
          isSuccess: false
        });
      }else{
        setOrderDisplayDetails(response.data.data);
        setVisualOrderStatus('');
        if(setPagination){
          setPagination({
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 0,
            totalOrders: 0,
          })};
      }
   
    
    })
    .catch((e) => {

      const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
      error(e, showToast, retriesDone);
    });
  });
};

const filterOrdersByStatus = (statusToFilter, setLoading, setOrderDisplayDetails, showToast, setPagination=null) => {
  const token = localStorageGetItem('userToken');
  setLoading(true)

  createAxios().then((axios) => {
    axios.get(`/admin/order/all?orderStatus=${statusToFilter}`,{
      headers: {
        'X-Auth-Token': token,
      },
    })
    .then((response) => {
      setLoading(false)
   setOrderDisplayDetails(response.data.data); 
      if(setPagination){
        setPagination({
          currentPage: 1,
          itemsPerPage: 20,
          totalPages: 0,
          totalOrders: 0,
        })};// Adjust based on your response structure
    })
    .catch((e) => {

      const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
      error(e, showToast, retriesDone);
    });
  });
};


const createOrder = (
  
  body, setLoading,showToast
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .post('/admin/order',body, {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
        
            setLoading(false);
     
          showToast({
            toastText: 'Order created succesfully',
            isSuccess: true
          })
          navigate('/admin/orders')
         
        })
       
        .catch((error) => {
          // setLoading(false);
         
            //  const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            // error(e, setLoading, showToast, retriesDone);
        
        
      
       
          
        });
    });
};

const editOrder = (
  
  body,orderId, setLoading,showToast
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .put(`/order/${orderId}`,body, {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
        
            setLoading(false);
            console.log(response,'edit order')
     
          showToast({
            toastText: 'Order edited succesfully',
            isSuccess: true
          })
          navigate('/admin/orders')
         
        })
       
        .catch((e) => {
          setLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        
      
       
          
        });
    });
};

const bulkStatusUpdate = (
  
  body, setLoading,showToast,setSelectedOrdersId = []
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .patch('/admin/order/bulk-update-status',body, {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
        
            setLoading(false);
     
      
          showToast({
            toastText: 'Order updated succesfully',
            isSuccess: true
          })
          
          if(setSelectedOrdersId){
            window.location.reload();
          }
          else
          { navigate('/admin/orders')}
        
           
          
         
         
        })
       
        .catch((e) => {
          // setLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        
      
       
          
        });
    });
};
const getDeliveryStates = (
  
  showToast, setDeliveryStates
  
  
) => {
  
  // setLoading(true);

 
  createAxios()
    .then((axios) => {
      axios
      .get('/location/state')
        .then(async (response) => {
          console.log(response,'STATE')
         setDeliveryStates(response?.data)
      //    setTimeout(() => {
             
      //     setLoading(false);
      // }, 1000);
         })
       
        .catch((e) => {
          // setLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);
        
        
      
       
          
        });
    });
};
const getDeliveryLGAs = (stateId, showToast, setLgaOptions , setOriginalLgaOptions) => { 
  createAxios()
    .then((axios) => {
      axios
      .get(`/location/state/${stateId}/lga`)
        .then(async (response) => {
          console.log(response,'lgas')
     const lgas = response?.data.map(lga => ({
      value: lga.name,
      label: lga.name,
      id: lga.id,
      deliveryFee: lga.deliveryFee 
    }));
   
  

  
      setLgaOptions(lgas);
    setOriginalLgaOptions(lgas);

         }).catch((e) => {
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);  
        });
    });
};

const getOrderDeliveryLGAs = (stateId, showToast, orderDetails, setOrderDetails, index) => { 
  createAxios()
    .then((axios) => {
      axios
      .get(`/location/state/${stateId}/lga`)
        .then(async (response) => {
     const lgas = response?.data.map(lga => ({
      value: lga.name,
      label: lga.name,
      id: lga.id,
      deliveryFee: lga.deliveryFee 
    }));
   

    const updatedOrderDetails = orderDetails.map((item, idx) =>
      idx === index ? { ...item, lgaOptions: lgas, originalLgaOptions:lgas, } : item
    );

    // Set updated orderDetails state
    setOrderDetails(updatedOrderDetails);

         }).catch((e) => {
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);  
        });
    });
};





  return {
    
     createOrder,getAdminSingleOrder, getGlobalOrders, getAdminOrders, filterOrdersByStatus, bulkStatusUpdate, filterOrdersByDate, getDeliveryLGAs, getDeliveryStates,  getOrderDeliveryLGAs,orderModify,filterOrders, editOrder
    };
};