
import createAxios from './httpCommon';
import Error from './error';
import { useNavigate } from 'react-router-dom';
import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../App';
import { contextType } from 'react-quill';

 



export default () => {
  const { error } = Error();
  const navigate = useNavigate();
  
  const getShoppingInstructions =  (
    setLoading, showToast, setInstructions,
   
  ) => {
 
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/product/shopping-instructions`;
      axios.get(url,{headers: {
        'X-Auth-Token': token,
      },
      
    })
        .then((response) => {
       
           setInstructions(response?.data?.data)


setTimeout(() => {
             
  setLoading(false);
}, 1000);
    
        })
        .catch((e) => {
          // setLoading(false);
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        });
        
    });
  };

  const addShoppingInstruction =  (
   body,setShowInstructionModal, setLoading, showToast,
   
  ) => {
 
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/product/shopping-instructions`;
      axios.post(url,body,{headers: {
        'X-Auth-Token': token,
      },
      
    })
        .then((response) => {
         
    
           setShowInstructionModal(false)
           showToast({toastText: 'Shopping instruction added', isSuccess: true});

           
window.location.reload()
    
        })
        .catch((e) => {
          // setLoading(false);
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        });
        
    });
  };

  const getAllProductCategory =  (
    setLoading, showToast, setAllCategories 
   
  ) => {
 
   
    setLoading(true);
    createAxios().then((axios) => {
      axios
        .get('/product/category')
        .then(async (response) => {
        
          
          
            const gotResponse =  response?.data?.categories;
           


 
 const allCategories = ['All', 'Trending', ...gotResponse];
 setAllCategories(allCategories)
 setTimeout(() => {
             
  setLoading(false);
}, 1000);
    
        })
        .catch((e) => {
          // setLoading(false);
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        });
        
    });
  };
  const getProductCategory = (
    setLoading,
    showToast,
    setCategories,
    pagination = null,
    setPagination = null,
    searchCategory = ''
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    // Adjust pagination if it exists
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    const token = localStorageGetItem('userToken');
    setLoading(true);
  
    createAxios().then((axios) => {
      // Initialize URL
      let url = '/admin/product/categories';
  
      // Append page and size parameters if pagination exists
      if (pagination) {
        url += `?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
      }
  
      // Append search term to URL if provided
      if (searchCategory) {
        // Add the search parameter. Check if it's the first parameter or not to add '&' or '?' accordingly
        url += pagination ? `&search=${searchCategory}` : `?search=${searchCategory}`;
      }
  
      axios
        .get(url, {
          headers: {
            'X-Auth-Token': token,
          },
        })
        .then((response) => {
         
          
  
          
         
  
          if (setPagination) {
            const gotResponse = response?.data?.data?.categories;
  
          const newPagination = response?.data?.data?.pagination;
          setCategories(gotResponse);
            setPagination({
              currentPage: newPagination.currentPage,
              itemsPerPage,
              totalPages: newPagination.totalPages,
              totalCategories: newPagination.totalCategory,
            });
          }
          else{
            setCategories(response?.data?.data);
          }
  
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((e) => {
          const retriesDone = e.config['axios-retry']
            ? e.config['axios-retry'].retryCount >= 1
            : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };
  

  const filterProductsByCategory = (categoryId, setLoading, setProducts, showToast, searchProduct = '',pagination = null,
    setPagination = null
    ) => {
   
      let currentPage = 1;
      let itemsPerPage = 20;
    
      // Adjust pagination if it exists
      if (pagination) {
        currentPage = pagination.currentPage;
        itemsPerPage = pagination.itemsPerPage;
      }
      setLoading(true);
      createAxios().then((axios) => {
        let url = `/product?categoryId=${categoryId}&?page=${currentPage}&size=${itemsPerPage}`;
        if (searchProduct) {
          url += `&search=${searchProduct}`;
        }
    
        axios.get(url)
          .then((response) => {
           
  
     
        setProducts(response?.data?.data?.products); 
        if (setPagination) {
          setPagination({
            currentPage: response?.data?.data?.pagination?.currentPage,
            itemsPerPage,
            totalPages: response?.data?.data?.pagination?.totalPages,
            totalProducts: response?.data?.data?.pagination?.totalProducts,
          });
        }
        setTimeout(() => {
             
          setLoading(false);
        }, 1000);
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone);
      });
    });
  };
  
  // Assuming the existence of necessary hooks and context for setLoading, showToast, etc.
 
  const filterCategoryByStatus = (statusFilter,setLoading, setCategories, showToast,pagination,setPagination = null) => {
    setLoading(true)
    const token = localStorageGetItem('userToken');
    createAxios().then((axios) => {
      axios.get(`/admin/product/categories?status=${statusFilter}`,{headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {

        if (response?.data?.data?.length === 0){
          setLoading(false)
          showToast({toastText: `No category are currently of ${statusFilter} type.`, isSuccess: false});
        }else{
          setCategories(response.data.data); 
          if(setPagination){
          setPagination({
            ...pagination,
            totalCategories: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone); 
      });
    });
  };
  const editCategory = (
    categoryId,
    formData,
    setLoading,
    showToast,
 
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.put(`/product/category/${categoryId}`, formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 

      },
      
    })
        .then((response) => {
        
          setLoading(false);
          showToast({toastText:'Category modified successfully', isSuccess:true})
         
          navigate('/admin/categories')
          

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  const deleteCategory = (
    categoryId,
    body,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.patch(`/admin/product/categories/status/${categoryId}`,body, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
         
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:`Category ${body?.status === 'active' ? 'activated' :'deactivated'} successfully`, isSuccess:true})
          setTimeout(() => {
             
            window.location.reload();
        }, 1000);
        

         
        }).catch((e) => {
          setShowDeleteModal(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const createCategories = (
  
    formData, setLoading,showToast
    
    
  ) => {
    
    setLoading(true);
    const token = localStorageGetItem('userToken');
   
    createAxios()
      .then((axios) => {
        axios.post('/product/categories', formData, {headers: {
          'X-Auth-Token': token,
          "Content-Type": "multipart/form-data", 
        },
      })
          .then(async (response) => {
        
              setLoading(false);
       
            showToast({
              toastText: 'Category created succesfully',
              isSuccess: true
            })
            navigate('/admin/categories')
           
          })
         
          .catch((e) => {
            // setLoading(false);
           
               const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
              error(e, setLoading, showToast, retriesDone);
          
          
        
         
            
          });
      });
  };

  const getGlobalProducts = (
    setLoading,
    showToast,
    setGlobalProducts,
    searchProduct = '',
  ) => {
  
    setLoading(true);
    createAxios().then((axios) => {
      let url = '/product';
      
      if (searchProduct) {
        url += `?search=${searchProduct}`;
      }
      axios.get(url)
        .then((response) => {
          setLoading(false);
          if(searchProduct){
            const initializedProducts = response?.data?.data?.products.map(product => ({
              ...product,
              quantity: 0, 
            }));
    
          
              setGlobalProducts(initializedProducts);
          }else{
            const initializedProducts = response?.data?.data?.products?.map(product => ({
              ...product,
              quantity: 0, 
            }));
             
    
          
              setGlobalProducts(initializedProducts);
          }
          
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };


  const getProducts = (
    setLoading,
    showToast,
    setProducts,
    pagination,
    setPagination,
    searchProduct = '',
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/product?page=${currentPage}&size=${itemsPerPage}`;
      
      if (searchProduct) {
        url += `&search=${searchProduct}`;
      }
  
      axios.get(url)
        .then((response) => {
      if(searchProduct){console.log(response, 'search')}
          const { products, pagination: newPagination } = response.data.data;
          const initializedProducts = products.map(product => ({
            ...product,
            quantity: 0, // Initialize quantity to 0
          }));
  
         
            // On larger screens, replace products
            setProducts(initializedProducts);
        
  
          // Update pagination state
          if (setPagination) {
            setPagination({
              currentPage: newPagination.currentPage,
              itemsPerPage,
              totalPages: newPagination.totalPages,
              totalProducts: newPagination.totalProducts,
            });
          }

          setTimeout(() => {
             
            setLoading(false);
        }, 1000);
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  
  const getSingleProduct = (
    productId,
    setLoading,
    showToast,
    setInitialProductData,
    setProductData
  ) => {
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/product/${productId}`)
        .then((response) => {
          
          const formatStockLevel = (stockLevel) => {
            return stockLevel
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join('-');
          };

          const getSeasonStatus = (inSeason) => {
            if (typeof inSeason === 'boolean') {
              return inSeason ? 'In Season' : 'Off Season';
            }
            return '';  // Return empty string if inSeason is undefined or not a boolean
          };
          const categoryNames = response?.data?.data?.categories.map(cat => cat.categoryName)
      
            const instructionNames = response?.data?.data?.shoppingInstructions.map(instruction => instruction.name)
          
          setInitialProductData({
           
            productName:response?.data?.data?.productName|| '',
            productDescription:response?.data?.data?.description|| '',
            productQuantity:response?.data?.data?.quantityAvailable|| '',
            seasonStatus: getSeasonStatus(response?.data?.data?.inSeason),
            productPrice:response?.data?.data?.price|| '',
             productCategory: categoryNames || [],
             shoppingInstructions: response?.data?.data?.shoppingInstructions?.length > 0 ? instructionNames : [],
            productStockLevel: formatStockLevel(response?.data?.data?.stockLevel || ''),
            stockLevelAlert:response?.data?.data?.stockLevelAlert || '',
            productImage:response?.data?.data?.productImage|| '',
            dateAdded:response?.data?.data?.createdAt || '',
            quantitySold:response?.data?.data?.quantitySold|| '',
        
        })
          setProductData({
           
              productName:response?.data?.data?.productName|| '',
              productDescription:response?.data?.data?.description|| '',
              productQuantity:response?.data?.data?.quantityAvailable|| '',
              seasonStatus: getSeasonStatus(response?.data?.data?.inSeason),
              productPrice:response?.data?.data?.price|| '',
              productCategory: categoryNames || [],
              shoppingInstructions: response?.data?.data?.shoppingInstructions?.length > 0 ? instructionNames : [],
              productStockLevel: formatStockLevel(response?.data?.data?.stockLevel || ''),
              stockLevelAlert:response?.data?.data?.stockLevelAlert || '',
              productImage:response?.data?.data?.productImage|| '',
              dateAdded:response?.data?.data?.createdAt || '',
              quantitySold:response?.data?.data?.quantitySold|| '',
          
          })
          setTimeout(() => {
             
            setLoading(false);
        }, 1000);
  
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  

  const getMoreProducts = (
    setLoadingMore,
    showToast,
    setProducts,
    pagination = null,
    setPagination = null,
   
   
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      ({ currentPage, itemsPerPage } = pagination);
    }
 
      setLoadingMore(true); 
    createAxios().then((axios) => {
      axios.get(`/product?page=${currentPage}&size=${itemsPerPage}`)
        .then((response) => {
        
            setLoadingMore(false)
       
          const { products, pagination } = response.data.data; // Updated to match your API response
          const initializedProducts = products.map(product => ({
            ...product,
            quantity: 0
          }));
          setProducts(prevProducts => {
                // Combine previous products with new ones
                const combinedProducts = [...prevProducts, ...initializedProducts];
                
                return combinedProducts.reduce((acc, current) => {
                  const x = acc.find(item => item.id === current.id);
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, []);
              });
         
  
          if (setPagination) {
            setPagination({ // Updated to directly use the pagination from the API
              currentPage: pagination.currentPage,
              itemsPerPage, // Assuming itemsPerPage is the same as requested
              totalPages: pagination.totalPages,
              totalProducts: pagination.totalProducts // Optionally store totalProducts if needed
            });
          }
        })
        .catch((e) => {
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);
        });
    });
  };

  const getProductswithSearch = (query,setSearchLoading,showToast,setProducts, setSearchResults, setSearchPerformed, pagination, setPagination) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    setSearchLoading(true);
    createAxios().then((axios) => {
      axios.get(`/product?page=${currentPage}&size=${itemsPerPage}&search=${query}`)
        .then((response) => {
          setSearchLoading(false);

          const { products, pagination: newPagination, resultMatches } = response.data.data;
          const initializedProducts = products.map(product => ({
            ...product,
            quantity: 0, // Initialize quantity to 0
          }));
          setSearchPerformed(true);
          setSearchResults(resultMatches)
  
            setProducts(initializedProducts);
        
  
          // Update pagination state
          if (setPagination) {
            setPagination({
              currentPage: newPagination.currentPage,
              itemsPerPage,
              totalPages: newPagination.totalPages,
              totalProducts: newPagination.totalProducts,
            });
          }
        }).catch((e) => {
          setSearchLoading(false);
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, showToast, retriesDone);
        });
    });
  };
  
  const addProduct = (
    formData,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.post('/admin/product', formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 
      },
      
    })
        .then((response) => {
          setLoading(false);
          showToast({toastText:'Product added successfully', isSuccess:true})
          navigate('/admin/products')

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const editProduct = (
    productId,
    formData,
    setLoading,
    showToast,
    contentType,
    state
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.put(`/admin/product/${productId}`, formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": contentType, 
      },
      
    })
        .then((response) => {
          setLoading(false);
          showToast({toastText:'Product modified successfully', isSuccess:true})
          if (state) {
            
            const currentPage = state.currentPage;
         
              navigate('/admin/products', { state: { currentPage } });
          } else{
          navigate('/admin/products')
          }

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const deleteProduct = (
    productId,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.delete(`/admin/product/${productId}`, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:'Product deleted successfully', isSuccess:true})
          setTimeout(() => {
             
            window.location.reload();
        }, 1000);
        

         
        }).catch((e) => {
          setShowDeleteModal(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const bulkPriceUpdate = (
   body,
    setLoading,
    showToast,
    state
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.put('/admin/products/price/bulk', body, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          showToast({toastText:'Prices updated successfully', isSuccess:true})
          if (state) {
            
            const currentPage = state.currentPage;
         
              navigate('/admin/products', { state: { currentPage } });
          } else{
          navigate('/admin/products')
          }


         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
 
  const bulkStockUpdate = (
    body,
     setLoading,
     showToast,
     state
  
   ) => {
     const token = localStorageGetItem('userToken');
     setLoading(true);
     createAxios().then((axios) => {
       axios.put('/admin/products/stock/bulk', body, {headers: {
         'X-Auth-Token': token
       },
       
     })
         .then((response) => {
           setLoading(false);
           showToast({toastText:'Stock levels updated successfully', isSuccess:true})
           if (state) {
            
            const currentPage = state.currentPage;
         
              navigate('/admin/products', { state: { currentPage } });
          } else{
          navigate('/admin/products')
          }

 
          
         }).catch((e) => {
        
            const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
             error(e, setLoading, showToast, retriesDone); 
         });
     });
   };

   const filterProducts = (
    setLoading,
    setProducts,
    showToast,
    setPagination = null,
    filters = {}
  ) => {
    setLoading(true);
    createAxios().then((axios) => {
      // Initialize URL
      let url = '/product';
      
      const params = new URLSearchParams();
      
      // Append filters to URL if provided
      if (filters.categoryId) {
        params.append('categoryId', filters.categoryId);
      }
      if (filters.stockLevel) {
        params.append('stockLevel', filters.stockLevel);
      }
      if (filters.seasonStatus !== undefined) {
        params.append('inSeason', filters.seasonStatus);
      }
  
      // Add the parameters to the URL
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
  
      axios
        .get(url)
        .then((response) => {
       
  
          if (response?.data?.data?.products?.length === 0) {
            setLoading(false);
            if (filters.categoryId && !filters.seasonStatus && !filters.stockLevel) {
              showToast({ toastText: 'No products in this category', isSuccess: false });
            } else if (!filters.categoryId && filters.seasonStatus !== undefined && !filters.stockLevel) {
              showToast({ toastText: `No products are currently ${filters.seasonStatus ? 'in season' : 'off season'}.`, isSuccess: false });
            } else if (!filters.categoryId && !filters.seasonStatus && filters.stockLevel) {
              showToast({ toastText: `No products are ${filters.stockLevel.replace('_', ' ')}.`, isSuccess: false });
            }
            else (
              showToast({ toastText: 'No products match', isSuccess: false })
            )
          } else {
            setProducts(response.data.data?.products);
            if (setPagination) {
              setPagination({
                currentPage: 1,
                itemsPerPage: 20,
                totalPages: 0,
                totalProducts: 0,
              });
            }
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        })
        .catch((e) => {
          const retriesDone = e.config['axios-retry']
            ? e.config['axios-retry'].retryCount >= 1
            : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };
  
  //  const filterProducts = (
  //   setLoading,
  //   showToast,
  //   setProducts,
  //   setPagination = null,
  //   categoryId = '',
  //   stockLevel = '',
  //   seasonStatus= ''
  // ) => {
    
  
  
  //   createAxios().then((axios) => {
  //     // Initialize URL
  //     let url = '/product';
  
  //     // Append page and size parameters if pagination exists
  //     if (categoryId) {
  //       url += `?categoryId=${categoryId}`;
  //     }
  
  //     // Append search term to URL if provided
  //     if (stockLevel) {
  //       // Add the search parameter. Check if it's the first parameter or not to add '&' or '?' accordingly
  //       url += categoryId ? `&stockLevel=${stockLevel}` : `?stockLevel=${stockLevel}`;
  //     }

  //     if (seasonStatus) {
  //       // Add the search parameter. Check if it's the first parameter or not to add '&' or '?' accordingly
  //       url += categoryId || stockLevel ? `&?inSeason=${seasonStatus}` : `?inSeason=${seasonStatus}`;
  //     }
  
  //     axios
  //       .get(url)
  //       .then((response) => {
  //         console.log(response, 'filtered products');
          
  //         if (response?.data?.data?.products?.length === 0){
  //           setLoading(false)
  //         if(categoryId !== '' && seasonStatus === '' && stockLevel === ''){
  //           showToast({toastText: 'No products in this category', isSuccess: false});
  //         }
  //         else if(categoryId === '' && seasonStatus !== '' && stockLevel === ''){
  //           showToast({toastText: `No products are currently ${seasonStatus === true ? 'in season' : 'off season'}.`, isSuccess: false});
  //         }
  //         else if(categoryId === '' && seasonStatus === '' && stockLevel !== ''){
  //           showToast({toastText:`No products are ${stockLevel.replace('_', ' ')}.`, isSuccess:false});
  //         }
  //       }else{
  //         setProducts(response.data.data?.products); 
  //         if(setPagination){
  //         setPagination({
  //           currentPage: 1,
  //           itemsPerPage: 20,
  //           totalPages: 0,
  //           totalProducts: 0,
  //         })};
  //         setTimeout(() => {
               
  //           setLoading(false);
  //       }, 1000);
  //       }
         
  
  //       })
  //       .catch((e) => {
  //         const retriesDone = e.config['axios-retry']
  //           ? e.config['axios-retry'].retryCount >= 1
  //           : true;
  //         error(e, setLoading, showToast, retriesDone);
  //       });
  //   });
  // };
   const filterByCategory = (categoryId,setLoading, setProducts, showToast, setPagination=null) => {
    setLoading(true)
  
    createAxios().then((axios) => {
      axios.get(`/product?categoryId=${categoryId}`)
      .then((response) => {
     if(response?.data?.data?.length === 0){
      setLoading(false)
      showToast({toastText: 'No products in this category', isSuccess: false});
     }else{setProducts(response.data.data);
      if(setPagination){
        setPagination({
          currentPage: 1,
          itemsPerPage: 20,
          totalPages: 0,
          totalProducts: 0,
        })}; 
      setTimeout(() => {
           
        setLoading(false);
    }, 1000);}
        
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };


   const filterByStockLevel = (stockLevel,setLoading, setProducts, showToast,setPagination=null,categoryId = null,) => {
    setLoading(true)
  
    createAxios().then((axios) => {
      let url = `/product?stockLevel=${stockLevel}`;
      if (categoryId) {
        url += `&categoryId=${categoryId}`;
      }
      axios.get(url)
      .then((response) => {
     
        if (response?.data?.data.products?.length === 0){
          setLoading(false)
          showToast({toastText:`No products are ${stockLevel.replace('_', ' ')}.`, isSuccess:false});
        }else{
          setProducts(response.data.data?.products); 
          if(setPagination){
            setPagination({
              currentPage: 1,
              itemsPerPage: 20,
              totalPages: 0,
              totalProducts: 0,
            })};
          setLoading(false)
        }
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };

  const filterBySeason = (seasonStatus,setLoading, setProducts, showToast,setPagination = null,categoryId = null,) => {
    setLoading(true)
  
    createAxios().then((axios) => {
      // axios.get(`/product?inSeason=${seasonStatus}`)
      let url = `/product?inSeason=${seasonStatus}`;
      if (categoryId) {
        url += `&categoryId=${categoryId}`;
      }
      axios.get(url)
      .then((response) => {
       
        if (response?.data?.data?.products?.length === 0){
          setLoading(false)
          showToast({toastText: `No products are currently ${seasonStatus === true ? 'in season' : 'off season'}.`, isSuccess: false});
        }else{
          setProducts(response.data.data?.products); 
          if(setPagination){
          setPagination({
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 0,
            totalProducts: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };


  const addBox = (
    formData,
    setBoxLoading,
    showToast,
    setShowSuccessModal
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setBoxLoading(true);
    createAxios().then((axios) => {
      axios.post('/subscription/box', formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 
      },
      
    })
        .then((response) => {
          setBoxLoading(false);
          setShowSuccessModal(true)
          setTimeout(() => {
            setShowSuccessModal(false)
            navigate('/admin/user-delights');
            
        }, 3000);

         
        }).catch((e) => {
          setBoxLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone); 
        });
    });
  };

  const editBox = (
    subscriptionId,
    formData,
    setLoading,
    showToast,contentType
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.put(`/subscription/box/${subscriptionId}`, formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": contentType, 
      },
      
    })
        .then((response) => {
          setLoading(false);
        
          showToast({toastText: 'Box updated successfully', isSuccess: true});
           
            navigate('/admin/user-delights');
            
      

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  const addVoucher = (
    formData,
    setLoading,
    showToast,
    setShowSuccessModal
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.post('/vouchers/admin', formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowSuccessModal(true)
          setTimeout(() => {
            setShowSuccessModal(false)
            navigate('/admin/user-delights');
            
        }, 3000);

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const editVoucher = (
    voucherId,
    formData,
    setLoading,
    showToast
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.put(`/vouchers/${voucherId}`, formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 
      },
      
    })
        .then((response) => {
          setLoading(false);
        
          showToast({toastText: 'Voucher updated successfully', isSuccess: true});
           
            navigate('/admin/user-delights');
            
      

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  const getAllBoxes = (
    setLoading, showToast, setSubscriptionBoxes, setSubscriptionDisplayBoxes, pagination,
    setPagination,
    searchSubscription = '',
 
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/admin/box?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
      
      if (searchSubscription) {
        url += `&search=${searchSubscription}`;
      }
  
      axios.get(url,{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
        
        
            setSubscriptionBoxes(response?.data?.data?.boxes);
            setSubscriptionDisplayBoxes(response?.data?.data?.boxes);
            if (setPagination) {
              setPagination({
                currentPage: response?.data?.data?.pagination?.currentPage,
                itemsPerPage,
                totalPages: response?.data?.data?.pagination?.totalPages,
                totalSubscriptions: response?.data?.data?.pagination?.totalBoxes,
              });
            }
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getGlobalBoxes = (
    setLoading, showToast, setGlobalSubscriptions
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/admin/box',{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        console.log(response, 'bxes')
            setGlobalSubscriptions(response?.data?.data);
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getSingleBoxes = (
    subscriptionId,
            setLoading,
            showToast,
            setInitialBoxData,
            setBoxData,
  ) => {

    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/subscription/box/${subscriptionId}`)
        .then((response) => {
         
          setInitialBoxData({
           
            boxName:response?.data?.data?.name || '',
            boxDescription:response?.data?.data?.description || '',
            boxPrice:response?.data?.data?.amount || '',
            items:response?.data?.data?.items || [],
            desktopBoxImage:  response?.data?.data?.boxImage || null,
            mobileBoxImage:  response?.data?.data?.mobileBoxImage || null,
          
        
        })
          setBoxData({
            boxName:response?.data?.data?.name || '',
            boxDescription:response?.data?.data?.description || '',
            boxPrice:response?.data?.data?.amount || '',
            items:response?.data?.data?.items || [],
            desktopBoxImage:  response?.data?.data?.boxImage || null,
            mobileBoxImage:  response?.data?.data?.mobileBoxImage || null,
          })

          // setSelectedTemplate({
          //   desktopBoxImage:  response?.data?.data?.boxImage || null,
          //   mobileBoxImage:  response?.data?.data?.mobileBoxImage || null,
          // })
         
          setTimeout(() => {
             
            setLoading(false);
        }, 1000);
  
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  
  const getSingleVoucher = (
    voucherId,
    setLoading,
    showToast,
    setInitialVoucherData,
    setVoucherData,
  ) => {
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/vouchers/${voucherId}`)
        .then((response) => {
         
          
          const voucher = response.data;
  
          const updatedVoucherData = {
            voucherName: voucher.name || '',
            voucherDescription: voucher.description || '',
            voucherPrice: voucher.amount || '',
            priceType: voucher.priceActor || '',
            desktopVoucherCover: voucher.image || voucher.desktopVoucherCover || null,
            desktopVoucherPreview: voucher.desktopVoucherPreview || null,
            desktopVoucherSpecialNote: voucher.desktopVoucherSpecialNote || '',
            mobileVoucherCover: voucher.mobileVoucherCover || null,
            mobileVoucherPreview: voucher.mobileVoucherPreview || null,
            mobileVoucherSpecialNote: voucher.mobileVoucherSpecialNote || '',
          };
  
          const updatedInitialVoucherData = { ...updatedVoucherData };
  
          if (voucher.validityPeriod && voucher.validityPeriodType) {
            updatedVoucherData.validityPeriod = `${voucher.validityPeriod} ${voucher.validityPeriodType}s after purchase`;
            updatedInitialVoucherData.validityPeriod = `${voucher.validityPeriod} ${voucher.validityPeriodType}s after purchase`;
          }
  
          setInitialVoucherData(updatedInitialVoucherData);
          setVoucherData(updatedVoucherData);
  
          setTimeout(() => {
            setLoading(false);
          }, 1000);
  
        }).catch((e) => {
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone);
        });
    });
  };

  
  const getAllVouchers = (
    setLoading, showToast, setVouchers, setDisplayVouchers, pagination,
    setPagination,
    searchVoucher = '',
 
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/admin/vouchers?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
      
      if (searchVoucher) {
        url += `&search=${searchVoucher}`;
      }
      axios.get(url,{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
    
            setVouchers(response?.data?.data?.vouchers);
            setDisplayVouchers(response?.data?.data?.vouchers);
            if (setPagination) {
              setPagination({
                currentPage: response?.data?.data?.pagination?.currentPage,
                itemsPerPage,
                totalPages: response?.data?.data?.pagination?.totalPages,
                totalVouchers: response?.data?.data?.pagination?.totalVouchers,
              });
            }
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getGlobalVouchers = (
    setLoading, showToast, setGlobalVouchers
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/admin/vouchers',{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
            setGlobalVouchers(response?.data?.data?.vouchers);
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getAllBoxTemplates = (
    setLoading, showToast, setBoxTemplates, setDisplayTemplates,pagination,
    setPagination,
 
  ) => {
    const token = localStorageGetItem('userToken');
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/admin/box/templates?pageParam=${currentPage}&sizeParam=${itemsPerPage}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
          setBoxTemplates(response?.data?.data?.templates);
            setDisplayTemplates(response?.data?.data?.templates);
          
            
            if (setPagination) {
              setPagination({
                currentPage: response?.data?.data?.paginaton?.currentPage,
                itemsPerPage,
                totalPages: response?.data?.data?.paginaton?.totalPages,
                totalTemplates: response?.data?.data?.paginaton?.totalTemplates
                
              });
             
            }
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getSingleBoxTemplate = (
    templateId,
    setLoading,
    showToast,
    setInitialBoxData,
    setBoxData,
 
  ) => {
    const token = localStorageGetItem('userToken');
  
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/admin/box/templates/${templateId}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
          setBoxData({
            templateGroupName:response?.data?.data?.groupName || '',
            mobileTemplate:response?.data?.data?.mobileTemplate || null,
            desktopTemplate: response?.data?.data?.desktopTemplate || null,
          })

          setInitialBoxData({
            templateGroupName:response?.data?.data?.groupName || '',
            mobileTemplate:response?.data?.data?.mobileTemplate || null,
            desktopTemplate: response?.data?.data?.desktopTemplate || null,
          })
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  const getSingleVoucherTemplate = (
    templateId,
    setLoading,
    showToast,
    setInitialVoucherData,
    setVoucherData,
 
  ) => {
    const token = localStorageGetItem('userToken');
  
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/admin/voucher/templates/${templateId}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {

          setInitialVoucherData({
            templateGroupName:response?.data?.data?.groupName || '',
          
            mobileCover:response?.data?.data?.mobileVoucherCover || null,
            desktopCover:response?.data?.data?.desktopVoucherCover || null,
            mobilePreview: response?.data?.data?.mobileVoucherPreview || null,
            desktopPreview:response?.data?.data?.desktopVoucherPreview || null,
            mobileNote:response?.data?.data?.mobileVoucherSpecialNote || null,
            desktopNote: response?.data?.data?.desktopVoucherSpecialNote || null
          })

          setVoucherData({
            templateGroupName:response?.data?.data?.groupName || '',
          
            mobileCover:response?.data?.data?.mobileVoucherCover || null,
            desktopCover:response?.data?.data?.desktopVoucherCover || null,
            mobilePreview: response?.data?.data?.mobileVoucherPreview || null,
            desktopPreview:response?.data?.data?.desktopVoucherPreview || null,
            mobileNote:response?.data?.data?.mobileVoucherSpecialNote || null,
            desktopNote: response?.data?.data?.desktopVoucherSpecialNote || null
          })

          setInitialVoucherData({
            templateGroupName:response?.data?.data?.groupName || '',
          
            mobileCover:response?.data?.data?.mobileVoucherCover || null,
            desktopCover:response?.data?.data?.desktopVoucherCover || null,
            mobilePreview: response?.data?.data?.mobileVoucherPreview || null,
            desktopPreview:response?.data?.data?.desktopVoucherPreview || null,
            mobileNote:response?.data?.data?.mobileVoucherSpecialNote || null,
            desktopNote: response?.data?.data?.desktopVoucherSpecialNote || null
          })
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  const editBoxTemplate = (
    templateId,
    formData,
    setLoading,
    showToast,
    contentType
 
  ) => {
    const token = localStorageGetItem('userToken');
  
    setLoading(true);
    createAxios().then((axios) => {
      axios.put(`/admin/box/template/${templateId}`,formData,{
        headers: {
          'X-Auth-Token': token,
          "Content-Type": contentType,  
        },
      })
        .then((response) => {
        setLoading(false)
  
          showToast({toastText:'Template updated successfully', isSuccess:true})
           navigate('/admin/subscriptions/templates')
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const editVoucherTemplate = (
    templateId,
    formData,
    setLoading,
    showToast,
 contentType
  ) => {
    const token = localStorageGetItem('userToken');
  
    setLoading(true);
    createAxios().then((axios) => {
      axios.post(`/admin/voucher/template/${templateId}`,formData,{
        headers: {
          'X-Auth-Token': token,
          
          "Content-Type": contentType,  
        },
      })
        .then((response) => {
        setLoading(false)

          showToast({toastText:'Template updated successfully', isSuccess:true})
           navigate('/admin/vouchers/templates')
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const filterVoucherByStatus = (activeStatus,setLoading, setDisplayVouchers, showToast,setPagination = null) => {
    setLoading(true)
    const token = localStorageGetItem('userToken');
    createAxios().then((axios) => {
      axios.get(`/admin/vouchers?isActive=${activeStatus}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then((response) => {
        if (response?.data?.data?.length === 0){
          setLoading(false)
          showToast({toastText: `No vouchers are currently ${activeStatus === true ? 'active' : 'inactive'}.`, isSuccess: false});
        }else{
          setDisplayVouchers(response.data.data?.vouchers); 
          if(setPagination){
          setPagination({
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 0,
            totalVouchers: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };

  const filterSubscriptionByStatus = (statusFilter, setLoading, setSubscriptionDisplayBoxes, showToast,pagination,setPagination = null) => {
    setLoading(true)
    const token = localStorageGetItem('userToken');
    createAxios().then((axios) => {
      axios.get(`/admin/box?status=${statusFilter}`,{headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {
        if (response?.data?.data.length === 0){
          setLoading(false)
          showToast({toastText: `No boxes are currently ${statusFilter}.`, isSuccess: false});
        }else{
          setSubscriptionDisplayBoxes(response?.data?.data); 
          if(setPagination){
          setPagination({...pagination,
            totalSubscriptions: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone); 
      });
    });
  };
  const getGlobalBoxTemplates = (
    setLoading, showToast, setGlobalTemplates
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/admin/box/templates',{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
            setGlobalTemplates(response?.data?.data);
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
   

  const getAllVoucherTemplates = (
    setLoading, showToast, setVoucherTemplates, setDisplayVoucherTemplates,pagination,
    setPagination,
 
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/admin/voucher/templates?pageParam=${currentPage}&sizeParam=${itemsPerPage}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
 setVoucherTemplates(response?.data?.data?.templates);
            setDisplayVoucherTemplates(response?.data?.data?.templates);
            if (setPagination) {
              setPagination({
                currentPage: response?.data?.data?.paginaton?.currentPage,
                currentPage: response?.data?.data?.paginaton?.currentPage,
                itemsPerPage,
                totalPages: response?.data?.data?.paginaton?.totalPages,
                totalTemplates: response?.data?.data?.paginaton?.totalTemplates,
              });
            }
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getGlobalVoucherTemplates = (
    setLoading, showToast, setGlobalVoucherTemplates
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/admin/voucher/templates',{
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
            setGlobalVoucherTemplates(response?.data?.data);
           setLoading(false);
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const addBoxTemplate = (
    formData,
    setLoading,
    showToast,
    setShowSuccessModal,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.post('/admin/box/template', formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 
      },
      
    })
        .then((response) => {

          setLoading(false);
          setShowSuccessModal(true)
          setTimeout(() => {
            setShowSuccessModal(false)
            navigate('/admin/subscriptions/templates');
            
        }, 5000);

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const addVoucherTemplate = (
    formData,
    setLoading,
    showToast,
    setShowSuccessModal,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.post('/admin/voucher/template', formData, {headers: {
        'X-Auth-Token': token,
        "Content-Type": "multipart/form-data", 
      },
      
    })
        .then((response) => {

          setLoading(false);
          setShowSuccessModal(true)
          setTimeout(() => {
            setShowSuccessModal(false)
            navigate('/admin/vouchers/templates');
            
        }, 5000);

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const deactivateBoxTemplate = (
    boxTemplateId,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.delete(`/admin/box/templates/${boxTemplateId}`, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:'Template deleted successfully', isSuccess:true})
          setTimeout(() => {
             
            window.location.reload();
        }, 1000);
        

         
        }).catch((e) => {
          setShowDeleteModal(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const deactivateVoucherTemplate = (
    voucherTemplateId,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');

    setLoading(true);
    createAxios().then((axios) => {
      axios.delete(`/admin/voucher/templates/${voucherTemplateId}`, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:'Template deleted successfully', isSuccess:true})
          setTimeout(() => {
             
            window.location.reload();
        }, 1000);
        

         
        }).catch((e) => {
          setShowDeleteModal(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const deactivateSubscription = (
    boxId,
    body,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');

    setLoading(true);
    createAxios().then((axios) => {
      axios.patch(`/admin/box/status/${boxId}`,body, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {

          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:`Box ${body.status === 'active' ? 'activated' : 'deactivated'} successfully`, isSuccess:true})
          setTimeout(() => {
             
            window.location.reload();
        }, 1000);
        

         
        }).catch((e) => {
          setShowDeleteModal(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const deactivateVoucher = (
    vucherId,
    body,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');

    setLoading(true);
    createAxios().then((axios) => {
      axios.patch(`/admin/voucher/status/${vucherId}`,body, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {

          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:`Voucher ${body.isActive === 'true' ? 'activated' : 'deactivated'} successfully`, isSuccess:true})
          setTimeout(() => {
             
            window.location.reload();
        }, 1000);
        

         
        }).catch((e) => {
          setShowDeleteModal(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  return {
   
getProducts, getProductCategory, getAllProductCategory,getMoreProducts, filterProductsByCategory, getProductswithSearch, getGlobalProducts, getSingleProduct, filterByCategory, addProduct,editProduct, bulkPriceUpdate, bulkStockUpdate, filterBySeason, filterByStockLevel,deleteProduct, editCategory, getAllBoxes, getSingleBoxes, getGlobalBoxes, getAllVouchers,getGlobalVouchers, getAllBoxTemplates, getGlobalBoxTemplates, getAllVoucherTemplates, getGlobalVoucherTemplates,addBoxTemplate, filterVoucherByStatus, addVoucherTemplate,deactivateBoxTemplate,deactivateVoucherTemplate,addBox,filterSubscriptionByStatus, deactivateSubscription, createCategories, deleteCategory,filterCategoryByStatus, getShoppingInstructions,addShoppingInstruction, createCategories, deleteCategory,filterCategoryByStatus, getShoppingInstructions,addShoppingInstruction, addVoucher, getSingleVoucher,editBox, editVoucher, getSingleBoxTemplate,editBoxTemplate,editVoucherTemplate,getSingleVoucherTemplate,deactivateVoucher,filterProducts
    };
};