import React, {useState, useEffect} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate} from 'react-router-dom'
import { Modal, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, InputGroup } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; 
import productRoute from '../../services/productRoute';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import edit from '../../assets/edit.svg'
import Select, { components }  from 'react-select';
import InstructionModal from '../../components/Modal/InstructionModal';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddProduct = () => {
    const navigate = useNavigate();
    const { showToast } = useAppContext();
    const { getProductCategory, filterByCategory, addProduct , getShoppingInstructions} = productRoute();
    const [loading, setLoading] = useState(false);
    const [instructions, setInstructions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [productData, setProductData] = useState({
        productName:'',
        productDescription:'',
        productQuantity:'',
        seasonStatus:'',
        productPrice:'',
        productCategory:[],
        productStockLevel:'',
        productImage:null,
        shoppingInstructions:[],
    })
    const [showInstructionModal, setShowInstructionModal] = useState(false);

    // State for managing new instruction input fields
    const [newInstructionName, setNewInstructionName] = useState('');
    const [newInstructionAmount, setNewInstructionAmount] = useState('');

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
    
            [{'list': 'ordered'}, {'list': 'bullet'}], // lists
            [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}], // indent
    
          
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
            [{'color': []}, {'background': []}], // dropdown with defaults
            [{'font': []}],
            [{'align': []}],
    
            ['clean'], // remove formatting
            ['link', 'image', 'video'] // link and image, video
        ]
    };
    
    useEffect(() => {
    

        getProductCategory(
          setLoading, showToast, setCategories
         
        )
    
        
      }, []); 
      useEffect(() => {
    

        getShoppingInstructions(
          setLoading, showToast, setInstructions
         
        )
    
        
      }, []); 
    const handleBack = ()=> {
        navigate(-1)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    
    const handleDescriptionChange = (value) => {
    
        setProductData(prev => ({ ...prev, productDescription: value }));
    };
    
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/') && file.size <= 10485760) {
            setProductData(prev => ({ ...prev, productImage: file }));
            // setEditorOpen(true);
        } else {
            showToast({toastText: 'Invalid file. Please upload a non-SVG image file up to 10MB.', isSuccess: false});
        }
    };

    
    const handleDeleteImage = () => {
        setProductData(prev => ({ ...prev, productImage: null }));
    };
    

    const isFormValid = () => {
       
        return productData?.productName &&
        productData?.productQuantity &&
        productData?.productPrice &&
        productData?.productCategory.length > 0 && 
        productData?.productStockLevel &&
        productData?.productImage
      };
      const stripHtml = (html) => {
        try {
            const temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = html;
            return temporalDivElement.textContent || temporalDivElement.innerText || "";
        } catch (error) {
            console.error('Error stripping HTML:', error);
            return "";  // Return empty string on error
        }
    };
    

    const handleSave = (e) => {
        e.preventDefault();
      
      
        const plainTextDescription = stripHtml(productData.productDescription);
        if(productData?.productDescription !== '' && (plainTextDescription.length < 8 || plainTextDescription.length > 400)) {
            showToast({ toastText: "Product description must be between 8 and 400 characters.", isSuccess: false });
            return;
        }

    
  
        const formData = new FormData();
        formData.append('productName', productData.productName);
       
        formData.append('quantityAvailable', productData.productQuantity);
        formData.append('price', productData.productPrice);
        productData.productCategory.forEach((category) => {
            formData.append('categories', category);
          });
        // formData.append('categories', JSON.stringify(productData.productCategory)); // Ensure this is the category ID
        formData.append('stockLevelAlert', productData.productStockLevel);
        formData.append('productImage', productData.productImage);
       
        if (productData.seasonStatus !== null && productData.seasonStatus !== '' && productData.seasonStatus !== undefined) {
            const inSeason = productData.seasonStatus === 'In Season';
            formData.append('inSeason', inSeason);
        }
        if (productData.productDescription !== null && productData.productDescription !== '' && productData.productDescription !== undefined) {
            formData.append('description', plainTextDescription);
        }
        if (productData.shoppingInstructions?.length > 0) {
        productData.shoppingInstructions.forEach((instruction,index) => {
            formData.append(`shoppingInstructions[${index}]`, instruction);
        
        });
        }

        addProduct (
            formData,
            setLoading,
            showToast,
            
         
          )

      
    };
    const categoryOptions = categories
    .filter(category => category.status === 'active') // Filter only active categories
    .map((category) => ({
        value: category._id,
        label: category.categoryName,
    }));

    const CustomMenuCategoryList = props => {
        return (
          <components.MenuList {...props}>
            {props.children}
           
          </components.MenuList>
        );
      };
    const options = instructions.map(instruction => ({
        value: instruction?.id,
        label: instruction?.name,
      }));
    
      // Custom MenuList to add "Add Shopping Instruction"
      const CustomMenuList = props => {
        return (
          <components.MenuList {...props}>
            {props.children}
            <div
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                borderTop: '1px solid #ddd',
                color: '#469B12',
              }}
              onClick={() => setShowInstructionModal(true)}
            >
            <FontAwesomeIcon icon={faPlus} />   Add Shopping Instruction
            </div>
          </components.MenuList>
        );
      };
 
  return (
    <div className=''>
        <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'>Add Product</h3>
        </div>

        <div className=' pe-5 ps-4 '>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Product Name <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='productName' value={productData.productName} onChange={handleChange} required/>
                </Col>
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Category <sup className='text-danger'>*</sup></p>
                <Select
                isMulti
                options={categoryOptions}
                onChange={selectedOptions => {
                    const selectedValues = selectedOptions.map(option => option.value);
                    setProductData(prev => ({
                      ...prev,
                      productCategory: selectedValues,
                    }));
                  }}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      zIndex: 2000, // Ensure the dropdown has a high z-index
                    }),
                    menuList: provided => ({
                      ...provided,
                      zIndex: 2000, // Ensure the dropdown options have a high z-index
                    }),
                  }}
                  components={{
                    MenuList: CustomMenuCategoryList,
                  }}
                  
            />
               {/* <Form.Select type='text' name='productCategory' value={productData.productCategory} onChange={handleChange} required>
              <option value="" disabled>Select category</option>
  {categories.map((category,index) => (
      <option value={category?.id} key={index}>{category?.categoryName}</option>
    ))}
    

                </Form.Select> */}
                
                </Col>

                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Price <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='productPrice' value={productData.productPrice} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setProductData({ ...productData, productPrice: numericValue })
                }} required/>
                </Col>
              
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Quantity <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='productQuantity' value={productData.productQuantity} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setProductData({ ...productData, productQuantity: numericValue })}} required/>
                
                </Col>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Stock Level Alert <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='productPrice' value={productData.productStockLevel} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setProductData({ ...productData, productStockLevel: numericValue })
                }} required/>
               
                
                </Col>
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Season Status:</p>
                <InputGroup>
                <Form.Select type='text' name='seasonStatus' value={productData.seasonStatus} onChange={handleChange} >
                <option value="" disabled>Select status</option>
    
                <option value='In Season'>In Season</option>
    <option value='Off Season'>Off Season</option>
  
                </Form.Select>
                {productData.seasonStatus &&
         <Button
              variant='secondary'
              onClick={() => setProductData({...productData, seasonStatus:''})}
              className=''
            >
              X
            </Button>}
            </InputGroup>
                
                </Col>
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Shopping Instructions</p>
                <Select
        isMulti
        options={options}
        onChange={selectedOptions => {
          const selectedValues = selectedOptions.map(option => option.value);
          setProductData(prev => ({
            ...prev,
            shoppingInstructions: selectedValues,
          }));
        }}
        noOptionsMessage={() => "No instructions available"}
        styles={{
          menu: provided => ({
            ...provided,
            zIndex: 2000, // Ensure the dropdown has a high z-index
          }),
          menuList: provided => ({
            ...provided,
            zIndex: 2000, // Ensure the dropdown options have a high z-index
          }),
        }}
        components={{
          MenuList: CustomMenuList,
        }}
      />
              
                
                </Col>
                <Col xs={12} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Product Description:</p>
                <ReactQuill value={productData.productDescription}  onChange={handleDescriptionChange}  modules={modules}/>
                
                </Col>

            </Row> 

           <div className='my-4'>
            {productData.productImage ? (
            <>
            <Button className='mb-4'>Attached Image</Button>
                            <div className="d-flex  gap-3">
                            <img
                            src={URL.createObjectURL(productData.productImage)}
                            onLoad={() => URL.revokeObjectURL(productData.productImage)}
                            alt="Product"
                            style={{ width: '100px', height: '100px'}}
                        />
                                <div className='d-flex gap-2 align-items-end'>
                                {/* <img src={edit} onClick={openImageEditor} style={{ width: '24px', height: '24px', cursor: 'pointer',}} />  */}
                                <img src={deleteIcon} onClick={handleDeleteImage} style={{ width: '24px', height: '24px', cursor: 'pointer',}} /></div>
                                
                            </div>
                            </>
                        ) : (<>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="fileInput"
                            />
                            <Button variant="secondary" onClick={() => document.getElementById('fileInput').click()} style={{ backgroundColor: '#CCCDCF', border:'none', borderRadius:'4px',  }} className='px-4'>
                            Upload Product Image<sup className='text-danger'>*</sup>
                        </Button></>
                        )}
                        </div>

                        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>

        </Form>}

        {/* {editorOpen && productData.productImage && (

     
        <Modal show={editorOpen} onHide={closeImageEditor} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FilerobotImageEditor
                        source={URL.createObjectURL(productData.productImage)}
                        onSave={onImageSave}
                        onClose={closeImageEditor}
                    />
                </Modal.Body>
            </Modal>
        )}
       */}
  
    
      </div>
      <InstructionModal showInstructionModal={showInstructionModal} setShowInstructionModal={setShowInstructionModal}/>
    </div>
  )
}

export default AddProduct
